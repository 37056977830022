import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Hernandohero from "../components/hero/Hernandohero";
import { FaSistrix } from "react-icons/fa";

function Hernando() {
  return (
    <Layout>
      <Hernandohero />
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h3>HERNANDO COUNTY</h3>
            <p>
              Hernando County was established in 1843 and named in honor of
              Spanish explorer Hernando de Soto. Now known as Florida's
              Adventure Coast, our freshwater rivers and springs, state forests
              and preserves and Gulf waters provide opportunities for many land
              and water adventures for residents and tourists.
            </p>
            <p>
              Our county is the geographic center of the state, and is located
              on the central-west coast of Florida. Businesses are drawn to this
              location because of our affordability, accessibility and ;abundant
              space to relocate or expand. It's a place where community and
              commerce thrive. These are just some of the reasons why Hernando
              is great place to start or grow a business.
            </p>
            <p>
              The City of Brooksville is the county seat and is rich in history
              and traditions. Weeki Wachee, the only other city in the county,
              incorporated many decades ago to appear on Florida maps to promote
              the Weeki Wachee Springs as the “City of Mermaids.” Unincorporated
              areas include Spring Hill, Ridge Manor, Ridge Manor West,
              Masaryktown, Bayport, Aripeka, Lake Lindsey and Hernando Beach.
            </p>
            <p>
              Known for the crystal clear waters of the Weeki Wachee River you
              can tube or kayak down the river and see all the fish, turtles and
              manatee’s like you are inside an aquarium tank! Or go watch the
              Mermaids Show at Weeki Wachee Springs Sate Park and let your kids
              play at the park all day! There are water slides and play areas
              for them as well as the shows!
            </p>
            <p>
              To the east is Brooksville where riding motorcycles through the
              countryside’s hills and winding roads is a favorite past time for
              many. There is also hunting and fishing and biking canoeing and
              camping opportunities.
            </p>
            <p>
              There are also several good hospitals, a state college and public
              airport located six miles south of the City of Brooksville.
            </p>
            <p>
              Then there is the city of Spring Hill, it is growing by leaps and
              bounds and is the most up and coming area in Hernando. New homes
              are being built everyday and it has a wonderful city in the
              country vibe to it.
            </p>
            <p>
              If you truly like old Florida check out Bayport or Pine Island, it
              will amaze you! Still unspoiled by progress it is like going back
              in time, with a few luxuries thrown in!
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <h5>General Faqs</h5>
            <ul>
              <li>
                <FaSistrix /> Population: 193,920 Estimate (2019)
              </li>
              <li>
                <FaSistrix /> County Seat: Brooksville
              </li>
              <li>
                <FaSistrix /> Largest community Spring Hill
              </li>
              <li>
                <FaSistrix /> Founded February 24, 1843
              </li>
              <li>
                <FaSistrix /> Hernando County is home to the largest
                (truck-to-truck) Wal-Mart Distribution Center in the U.S
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="mt-30">
        <Row className="mb-30">
          <h3>Service Locations for Hernando County</h3>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <ul>
              <li>
                <FaSistrix /> Spring Hill
              </li>
              <li>
                <FaSistrix /> Brooksville
              </li>
              <li>
                <FaSistrix /> Weeki Wachee
              </li>
              <li>
                <FaSistrix /> Hernando Beach
              </li>
              <li>
                <FaSistrix /> Ridge Manor
              </li>
              <li>
                <FaSistrix /> Pine Island
              </li>
            </ul>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <a
              href="/static/57faafbfbbe651d6d0accd86a99bdd8b/414ee/hernandomap.jpg"
              target="blank"
            >
              <StaticImage src="../images/hernandomap.jpg" />
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Hernando;
