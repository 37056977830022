import React from "react";
import { Container } from "react-bootstrap";

const Hernandohero = () => {
  return (
    <div className="overlay-hero banner banner-page banner-hernando mb-30">
      <Container>
        <div className="overlay-herotxt">
          <h2 className="banner-head ">Hernando County</h2>
        </div>
      </Container>
    </div>
  );
};

export default Hernandohero;
